import Vue from 'vue'
import VueRouter from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import HomeView from '../views/index/index.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '主页',
    component: HomeView
  },
     {
       path: '/userPrivacy',
       name: '用户隐私',
       // route level code-splitting
       // this generates a separate chunk (about.[hash].js) for this route
       // which is lazy-loaded when the route is visited.
       component: () => import(/* webpackChunkName: "about" */ '../views/index/userPrivacy.vue')
     }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
