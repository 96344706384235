<template>
  <div id="body" style="width: 100%;height: 100%;overflow: auto;">
    <top></top>
    <img src="../../../public/main.png" width="100%" height="100%" style="object-fit:fill" />
    <foot></foot>
  </div>
</template>

<script>
  import top from "../top/index.vue";
  import main from "../main/main.vue";
  import foot from "../foot/index";

  export default {
      components: {
        top,
        foot
      },
      name: "index",
      data() {
        return {
        };
      },
      mounted() {
      },
      methods: {
      }
    };
</script>

<style>
  #body{
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
</style>